import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/Layout"
import SEO from "../components/SEO"

const GalleryTemplate = ({ data, pageContext }) => {
  const { html } = data.markdownRemark
  const { title, author, description, featuredImg } = data.markdownRemark.frontmatter
  return (
    <Layout>
      <SEO title={pageContext.tag} />
      <main className="page gallery-page">
        <h3><strong>{title}</strong></h3>
        <p>{description}</p>
        <p>autor: {author}</p>
        <div className="origamiImg">
        <GatsbyImage
        image={getImage(featuredImg.childImageSharp.gatsbyImageData)}
         />
        </div>
        <div className="pageText" dangerouslySetInnerHTML={{ __html: html }} />
        <div className="backBtn">
              <Link to="/Gallery"  className="btn">
                &laquo; Povratak 
              </Link>
        </div>
      </main>
    </Layout>
  )
}

export const query = graphql` 
query galleryTemplate($slug: String) {
  markdownRemark(frontmatter: {slug: {eq: $slug}}) {
    html
    frontmatter {
      author
      date
      description
      title
      featuredImg {
        childImageSharp {
          gatsbyImageData(
            placeholder: DOMINANT_COLOR
            transformOptions: {fit: CONTAIN}
            width: 800
          )
        }
      }
    }
  }
}
`


export default GalleryTemplate
